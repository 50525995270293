<template>
  <b-container fluid>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <b-row>
          <b-col>
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title mt-2">Asignación De Permisos</h4>
              </template>
              <template v-slot:body>
                <div class="new-user-info">
                  <b-row class="ml-3">
                    <b-col
                      lg="3"
                      md="6"
                      class="mb-4"
                      v-for="(item, index) in permissions"
                      :key="index"
                    >
                      <b-form-checkbox-group
                        size="lg"
                        v-model="usersPermit"
                        :options="item"
                        stacked
                      ></b-form-checkbox-group>
                    </b-col>
                  </b-row>
                  <hr />
                  <b-button variant="primary" type="submit">Guardar</b-button>
                </div>
              </template>
            </iq-card>
          </b-col>
        </b-row>
      </form>
    </ValidationObserver>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
export default {
  name: 'Permits',
  async mounted () {
    this.$store.commit("SET_BREADCRUMB", this.items);
    core.index()
    this.$store.commit('run')

    // LISTADO DE PERMISOS
    const permits = await this.$store.dispatch('permissions/listPermissions')
    this.options = permits.map((item) => ({
      value: item.name,
      text: item.label,
      id: item.id
    }))

    let name = null
    this.options.forEach((perm) => {
      const name2 = perm.value.split('.')[0]
      if (name2 !== name) this.permissions.push([perm])
      else this.permissions[this.permissions.length - 1].push(perm)
      name = name2
    })
    // PERMISOS DE USUSARIOS
    const permitsUser = await this.$store.dispatch(
      'permissions/listPermissionsUser',
      this.id
    )
    this.usersPermit = permitsUser
    this.$store.commit('stop')
  },
  data () {
    return {
      id: this.$route.params.id,
      items: [
        {
          html: 'Inicio',
          to: '/'
        },
        {
          html: 'Listado de usuarios',
          to: '/users/list'
        },
        {
          text: 'Asignación de permisos',
          active: true
        }
      ],
      options: [],
      permissions: [],
      usersPermit: []
    }
  },
  methods: {
    async onSubmit () {
      const user = this.usersPermit
      const permit = this.options
      const permissions = []
      for (let i = 0; i < permit.length; i++) {
        for (let j = 0; j < permit.length; j++) {
          if (permit[i].value === user[j]) permissions.push(permit[i].id)
        }
      }
      try {
        const payload = {
          id: this.id,
          permissions: permissions
        }
        this.$store.commit('run')
        await this.$store.dispatch(
          'permissions/syncPermissionsUser',
          payload
        )
        this.$store.commit('stop')
        core.showSnackbar('success', 'Permisos Asignados')
        this.$router.replace('/users/list')
      } catch (error) {
        this.$store.commit('stop')
        const errors = error.response.data.errors
        for (const key in errors) {
          core.showSnackbar('error', errors[key][0])
          return false
        }
      }
    }
  }
}
</script>
